<script setup>
import { ref } from 'vue'
import route from 'ziggy-js'
import BgPayment from '../../images/bg-payment.png'

const categories = ref([
  {
    lable: 'Thẻ cào',
    url: route('recharge'),
  },
  // {
  //   lable: 'Thanh toán momo ( bảo trì 24h - 48h)',
  //   url: route('recharge.bank'),
  // },
  {
    lable: 'Thanh Toán Bank',
    url: route('recharge.bank'),
  },
])

const props = defineProps(['title_page', 'notes'])

const isActive = (url) => {
  const currentPath = window.location.pathname
  const normalizedCurrentPath = currentPath.endsWith('/')
    ? currentPath.slice(0, -1)
    : currentPath
  const normalizedUrlPath = new URL(url).pathname.endsWith('/')
    ? new URL(url).pathname.slice(0, -1)
    : new URL(url).pathname

  return normalizedCurrentPath === normalizedUrlPath
}
</script>

<style scoped>
@media (min-width: 600px) {
  #bg-payment {
    background-size: 100% 100%;
    background-image: url('../../images/bg-payment.png');
  }
}
@media (max-width: 600px) {
  #bg-payment {
    background: #fdf0e0;
  }
}
</style>

<template>
  <section class="">
    <div class="mx-auto max-w-7xl gap-10 mt-12 min-h-[400px]">
      <div class="p-5">
        <div class="flex mx-5 rounded-t-2xl bg-[#DDB4B4]">
          <a
            :href="category.url"
            v-for="(category, index) in categories"
            :key="index"
            :class="[
              'text-2xl w-full text-center font-[Inder]  border-e focus:ring-2 p-4   border-black',
              isActive(category.url) ? 'text-[#1A5276] ' : 'text-black',
            ]"
          >
            <span
              :class="[
                'text-left font-normal',
                isActive(category.url) ? 'underline ' : '',
              ]"
            >
              {{ category.lable }}
            </span>
          </a>
        </div>

        <div
          class="sm:px-24 px-5 py-8 sm:py-28 flex flex-wrap sm:flex-nowrap gap-2 border bg-no-repeat border-black mb-20"
          id="bg-payment"
        >
          <div class="w-full sm:w-[70%]">
            <slot></slot>
          </div>
          <div class="w-full sm:w-[30%] rounded-lg">
            <div
              :class="[
                ' mb-20 p-5',
                'ring-white/60  focus:outline-none focus:ring-2',
              ]"
            >
              <div
                v-html="notes"
                class="font-[Inder] font-normal text-xl"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
